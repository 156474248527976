import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Popover } from "react-bootstrap";
import Tooltip from "rc-tooltip";
import Datatable from "../../Common/Datatable";
import Loader from "assets/images/spinner-dark.svg";
import { capitalizeString, dateFormat, formatBytes } from "helpers/common";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { setAllMessageLogsAttachmentsThunk } from "slices/thunk";
import { getMmsFileApi } from "helpers/apiroute_helper";

const SmsAndMmsLogs = ({
  loading,
  rowsPerPage,
  currPage,
  logsData,
  handleRowClick,
  handlePageChange,
  handleRowsPerPageChange,
}: any) => {
  const dispatch = useDispatch<any>();

  const [showModal, setShowModal] = useState(false);
  const [hoverId, setHoverId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const selectData = createSelector(
    (state: any) => state.Messages,
    (messages) => ({
      allMessageLogsAttachments: messages.allMessageLogsAttachments,
      allMessageLogsUrls: messages.allMessageLogsUrls,
    })
  );

  const { allMessageLogsAttachments, allMessageLogsUrls } =
    useSelector(selectData);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date and Time</span>,
      minWidth: "220px",
      selector: (row: { CreatedDate: string }) => row.CreatedDate,
      cell: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      minWidth: "150px",
      selector: (cell: { direction: any }) => cell.direction,
      cell: (cell: { direction: any }) => {
        switch (cell.direction?.toLowerCase()) {
          case "mt":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-info text-info"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "inbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "outbound-api":
          case "outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-api", ""))}
              </span>
            );
          case "group-outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-", " "))}
              </span>
            );
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.direction) || "N/A"}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => a.direction.localeCompare(b.direction),
    },
    {
      name: <span className="font-weight-bold fs-sm">Type</span>,
      minWidth: "110px",
      selector: (row: { type: string }) => row.type,
      cell: (row: { type: string }) => {
        switch (row.type) {
          case "SMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-black text-black"
              >
                {row.type}
              </span>
            );
          case "MMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary-subtle text-muted"
              >
                {row.type}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {row.type}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => a.type.localeCompare(b.type),
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      minWidth: "130px",
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.sendersPhoneNumber.localeCompare(b.sendersPhoneNumber),
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      minWidth: "130px",
      selector: (row: { recieversPhoneNumber?: number | string }) =>
        row.recieversPhoneNumber,
      cell: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = Array.isArray(phoneNumbers)
          ? phoneNumbers.map((num) => num.trim())
          : phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];

        console.log("phoneArray", phoneArray);
        const maxInitialDisplay = 1;
        const hasMoreNumbers = phoneArray.length > maxInitialDisplay;

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phoneArray.slice(0, maxInitialDisplay).map((num, index) => (
              <span key={index}>{num}</span>
            ))}
            {hasMoreNumbers && (
              <>
                <span
                  className="btn btn-link p-0"
                  style={{
                    fontSize: "0.85rem",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  and {phoneArray.length - 1} more
                </span>
                {showModal && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.09)",
                        zIndex: 1040,
                      }}
                      onClick={() => setShowModal(false)}
                    />
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      backdrop={false}
                      style={{ zIndex: 1050 }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {phoneArray.map((num, index) => (
                          <div key={index}>{num}</div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aNumbers = a.recieversPhoneNumber?.toString().split(",")[0] || "";
        const bNumbers = b.recieversPhoneNumber?.toString().split(",")[0] || "";
        return aNumbers.localeCompare(bNumbers);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => row.messageBody,
      cell: (row: { messageBody: string }) => (
        <Tooltip
          placement="top"
          overlayClassName={
            row?.messageBody?.length > 1000 ? "largeTooltip" : ""
          }
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.messageBody}
            </p>
          }
        >
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        </Tooltip>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Attachments</span>,
      minWidth: "125px",
      selector: (row: { mediaURL: any; attachments: any }) =>
        row.attachments || row.mediaURL,
      cell: (row: {
        type: string;
        mediaURL: any;
        attachments: any;
        _id: string;
      }) =>
        row.type === "MMS" ? (
          <React.Fragment>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              onToggle={(nextShow) => {
                nextShow && tempfunc(row._id);
              }}
              overlay={
                <Popover id="popover-positioned-top">
                  <Popover.Body>
                    {row.mediaURL?.length > 0 ? (
                      <ul className="hstack gap-2 list-unstyled mb-0">
                        {isLoading2 ? (
                          <div
                            className="position-relative"
                            style={{ height: 50, width: 150 }}
                          >
                            <img
                              src={Loader}
                              className={`position-absolute top-50 start-50 translate-middle`}
                              alt="loading"
                              height={50}
                            />
                          </div>
                        ) : (
                          row.mediaURL?.map((dt: string, i: number) => (
                            <li
                              key={i}
                              className="position-relative"
                              onMouseEnter={() => setHoverId(`url-${i}`)}
                              onMouseLeave={() => setHoverId("")}
                            >
                              <a
                                className="mb-0 text-secondary"
                                style={{ wordBreak: "break-word" }}
                                href={dt}
                                target="_blank"
                              >
                                {hoverId === `url-${i}` && (
                                  <div className="d-flex align-items-center justify-content-center text-white position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-75 rounded">
                                    {formatBytes(
                                      allMessageLogsUrls?.[row._id]?.[i]?.size
                                    )}
                                  </div>
                                )}
                                <img
                                  src={dt}
                                  className="d-flex mx-auto img-thumbnail border-0 bg-transparent"
                                  style={{ height: 60 }}
                                />
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    ) : row.attachments?.length > 0 ? (
                      <ul className="hstack gap-2 list-unstyled mb-0">
                        {isLoading ? (
                          <div
                            className="position-relative"
                            style={{ height: 50, width: 150 }}
                          >
                            <img
                              src={Loader}
                              className={`position-absolute top-50 start-50 translate-middle`}
                              alt="loading"
                              height={50}
                            />
                          </div>
                        ) : (
                          row.attachments?.map((dt: any, i: number) => (
                            <li
                              key={i}
                              className="position-relative"
                              onMouseEnter={() => setHoverId(`att-${i}`)}
                              onMouseLeave={() => setHoverId("")}
                            >
                              <a
                                className="mb-0 text-secondary"
                                style={{ wordBreak: "break-word" }}
                                href={allMessageLogsAttachments?.[row._id]?.[i]}
                                target="_blank"
                              >
                                {hoverId === `att-${i}` && (
                                  <div className="d-flex align-items-center justify-content-center text-white position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-75 rounded">
                                    {formatBytes(dt?.fileSize || 0)}
                                  </div>
                                )}
                                <img
                                  src={
                                    allMessageLogsAttachments?.[row._id]?.[i]
                                  }
                                  className="d-flex mx-auto img-thumbnail border-0 bg-transparent"
                                  style={{ height: 60 }}
                                />
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    ) : (
                      "No Data available"
                    )}
                  </Popover.Body>
                </Popover>
              }
            >
              <i className="bi bi-file-image align-middle text-secondary fs-lg cursor-pointer px-3"></i>
            </OverlayTrigger>
          </React.Fragment>
        ) : (
          "-"
        ),
    },
    {
      name: (
        <span className="font-weight-bold fs-sm d-flex align-items-center">
          Segments{" "}
          <Tooltip
            placement="bottomRight"
            overlay={
              "Segments are calculated when a message is Sent. If a message fails prior to entering the “Sent” state, the message segments are not calculated."
            }
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>
        </span>
      ),
      minWidth: "150px",
      selector: (row: { segments?: string }) => row?.segments || "-",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aSegments = a.segments || "0";
        const bSegments = b.segments || "0";
        return parseInt(aSegments) - parseInt(bSegments);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      minWidth: "150px",
      selector: (cell: { latestStatus: any }) => cell.latestStatus,
      cell: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                <b data-tag="allowRowEvents">
                  {capitalizeString(
                    cell.latestStatus.replace("mo_message_", "")
                  )}
                </b>
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) =>
        a.latestStatus.localeCompare(b.latestStatus),
    },
  ];

  const tempfunc = (id: string) => {
    if (id) {
      const temp = logsData.records?.find((dt: any) => dt._id === id);

      const getMediaFile = async () => {
        setIsLoading(true);
        const promises =
          temp?.attachments?.length > 0 &&
          temp?.attachments?.map(async (dt: any) => {
            const formData = new FormData();
            formData.append("file", dt);
            return await getMmsFileApi(dt?.id);
          });
        const results =
          temp?.attachments?.length > 0 ? await Promise.all(promises) : [];

        dispatch(
          setAllMessageLogsAttachmentsThunk({
            attachments: {
              ...allMessageLogsAttachments,
              [temp._id]: results?.map((dt) => URL.createObjectURL(dt)),
            },
            urls: {
              ...allMessageLogsUrls,
            },
          })
        )
          .then(() => {
            setIsLoading(false);
            setTimeout(
              () => results?.map((dt) => URL.revokeObjectURL(dt)),
              100
            );
          })
          .catch(() => {
            setIsLoading(false);
            setTimeout(
              () => results?.map((dt) => URL.revokeObjectURL(dt)),
              100
            );
          });
      };

      const getFileFromUrl = async (url: string) => {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const fileName = url.substring(url.lastIndexOf("/") + 1);
        const file = new File([blob], fileName, {
          type: blob.type,
        });
        return file;
      };

      const temp2 = async () => {
        setIsLoading2(true);
        const promises =
          temp?.mediaURL?.length > 0 &&
          temp?.mediaURL?.map(async (dt1: any) => {
            return await getFileFromUrl(dt1);
          });
        const results =
          temp?.mediaURL?.length > 0 ? await Promise.all(promises) : [];

        dispatch(
          setAllMessageLogsAttachmentsThunk({
            attachments: { ...allMessageLogsAttachments },
            urls: {
              ...allMessageLogsUrls,
              [temp._id]: results,
            },
          })
        )
          .then(() => {
            setIsLoading2(false);
          })
          .catch(() => {
            setIsLoading2(false);
          });
      };

      if (!(temp._id in allMessageLogsAttachments)) getMediaFile();
      if (!(temp._id in allMessageLogsUrls)) temp2();
    }
  };

  return (
    <div className="position-relative">
      {loading ? (
        <div style={{ height: 60 }}>
          <img
            src={Loader}
            className={`position-absolute top-50 start-50 translate-middle`}
            alt="loading"
          />
        </div>
      ) : (
        <Datatable
          data={Array.isArray(logsData?.records) ? logsData?.records : []}
          columns={columns}
          handleRowClick={handleRowClick}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          currPage={currPage}
          rowsPerPage={rowsPerPage}
          totalRecords={logsData?.totalRecords}
        />
      )}
    </div>
  );
};
export default SmsAndMmsLogs;
