// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDetailsThunk,
  getTermsAndConditionsThunk,
  updateReviewCampaignThunk,
  openModal,
  msgFileUploadThunk,
  getMsgFileThunk,
  getTermsFromUsecaseListThunk,
  nudgeReviewCampaignThunk,
} from "slices/thunk";
import Loader from "assets/images/spinner-dark.svg";
import Loader2 from "assets/images/spinner-white.svg";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import Radiobox from "Common/Radiobox";
import TagsInput from "react-tagsinput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Lightbox from "react-image-lightbox";
import { onCloseSubject, submitLoadingSubject } from "Common/modals/Modal";

interface HoverPopoverProps {
  children: React.ReactNode;
  icon?: any;
  placement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end";
  className?: string;
  popoverClassName?: string; // Added for popover-specific styling
  title?: string; // Added for cases where you need a title
  width?: number | string; // Added for custom widths
  onOpen?: () => void; // Added for tracking/analytics
  onClose?: () => void; // Added for tracking/analytics
}

const HoverPopover: React.FC<HoverPopoverProps> = ({
  children,
  icon,
  placement = "bottom-end",
  className = "",
  popoverClassName = "",
  title,
  width,
  onOpen,
  onClose,
}) => {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
    onOpen?.();
  };

  const handleMouseLeave = () => {
    setShow(false);
    onClose?.();
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <OverlayTrigger
        show={show}
        placement={placement}
        overlay={
          <Popover
            id="hover-popover"
            className={`hover-popover ${popoverClassName}`}
            style={width ? { width } : undefined}
          >
            {title && <Popover.Header>{title}</Popover.Header>}
            <Popover.Body>{children}</Popover.Body>
          </Popover>
        }
      >
        {icon || (
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        )}
      </OverlayTrigger>
    </div>
  );
};

const CampaignReviewDetails = (props: any) => {
  document.title = "Signal House Portal Campaign";
  const dispatch = useDispatch<any>();
  const isRejected = props?.router?.location?.pathname?.includes("rejected");

  const [isInit, setIsInit] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isOpenDocLightBox, setIsOpenDocLightBox] = useState(false);
  const [isOpenVideoLightBox, setIsOpenVideoLightBox] = useState(false);
  const [campConditions, setCampConditions] = useState<any>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [attributeChanges, setAttributeChanges] = useState<any>({});
  const [show, setShow] = useState(false);
  const [isAttributeUpdating, setIsAttributeUpdating] = useState(false);

  const selectProfile = createSelector(
    (state: any) => state.Campaigns,
    (campaigns) => ({
      loading: campaigns.loading,
      loading2: campaigns.loading2,
      campaignDetails: campaigns.campaignDetails,
      campaignTnC: campaigns.campaignTnC,
      campaignMnoMetadata: campaigns.campaignMnoMetadata,
      campaignTerms: campaigns.campaignTerms,
      error: campaigns.error,
    })
  );

  const {
    loading,
    loading2,
    campaignDetails,
    campaignTnC,
    campaignMnoMetadata,
    campaignTerms,
  } = useSelector(selectProfile);

  const validation1: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optinKeywords: "",
      optinMessage: "",
    },
    validationSchema: Yup.object({
      optinKeywords: Yup.string().when("subscriberOptin", () => {
        return !campaignDetails?.subscriberOptin
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optinMessage: Yup.string().when("subscriberOptin", () => {
        return !campaignDetails?.subscriberOptin
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation2: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      optoutKeywords: "",
      optoutMessage: "",
    },
    validationSchema: Yup.object({
      optoutKeywords: Yup.string().when("subscriberOptout", () => {
        return !campaignDetails?.subscriberOptout
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      optoutMessage: Yup.string().when("subscriberOptout", () => {
        return !campaignDetails?.subscriberOptout
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  const validation3: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      helpKeywords: "",
      helpMessage: "",
    },
    validationSchema: Yup.object({
      helpKeywords: Yup.string().when("subscriberHelp", () => {
        return !campaignDetails?.subscriberHelp
          ? Yup.string().max(255).notRequired()
          : Yup.string().max(255).required("Please Enter Keywords");
      }),
      helpMessage: Yup.string().when("subscriberHelp", () => {
        return !campaignDetails?.subscriberHelp
          ? Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .notRequired()
          : Yup.string()
              .min(20, "message must be at least 20 characters")
              .max(320)
              .required("Please Enter Message");
      }),
    }),
    onSubmit: (values) => {
      handleUpdateCampaign(values);
    },
  });

  useEffect(() => {
    dispatch(getTermsAndConditionsThunk());
    if (props?.router?.params?.id) {
      dispatch(
        getCampaignDetailsThunk(
          isRejected
            ? `${props?.router?.params?.id}?campaignStatus=Rejected`
            : props?.router?.params?.id,
          false,
          true
        )
      );
    }
  }, [props?.router?.params?.id, props.router.location]);

  useEffect(() => {
    if (isInit) {
      setIsInit(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (campaignDetails?.optinKeywords)
      validation1.setFieldValue(
        "optinKeywords",
        campaignDetails?.optinKeywords
      );
    if (campaignDetails?.optinMessage)
      validation1.setFieldValue("optinMessage", campaignDetails?.optinMessage);
    if (campaignDetails?.optoutKeywords)
      validation2.setFieldValue(
        "optoutKeywords",
        campaignDetails?.optoutKeywords
      );
    if (campaignDetails?.optoutMessage)
      validation2.setFieldValue(
        "optoutMessage",
        campaignDetails?.optoutMessage
      );
    if (campaignDetails?.helpKeywords)
      validation3.setFieldValue("helpKeywords", campaignDetails?.helpKeywords);
    if (campaignDetails?.helpMessage)
      validation3.setFieldValue("helpMessage", campaignDetails?.helpMessage);
    setselectedFiles(campaignDetails?.attachments || []);

    if (campaignDetails?.brandId && campaignDetails?.usecase)
      dispatch(
        getTermsFromUsecaseListThunk(
          campaignDetails?.brandId,
          campaignDetails?.usecase
        )
      );
  }, [campaignDetails]);

  useEffect(() => {
    const temp2 =
      campaignMnoMetadata &&
      Object.fromEntries(
        Object.entries(campaignMnoMetadata).filter(
          ([key, value]: any) => value?.tpmScope === "CAMPAIGN"
        )
      );
    setCampConditions((temp2 && Object.values(temp2)[0]) || {});
  }, [campaignMnoMetadata]);

  const campaignDetailsSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    handleUpdateCampaign2({ obj: values, cb });
  };

  const sampleMessagesSubmit = ({ values, resetForm }: any) => {
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
    };
    const tempMsg: { [key: string]: string } = {};
    values?.messages?.forEach((dt: string, i: number) => {
      tempMsg[`sample${i + 1}`] = dt;
    });

    const tempPayload = { ...tempMsg };
    handleUpdateCampaign2({ obj: tempPayload, cb });
  };

  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error(err.message || "Something went wrong");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      setIsLoading(true);

      const cb = (files: any) => {
        if (typeof files[0] === "object" && files[0] !== null) {
          const cb3 = () =>
            // isRejected
            //   ? props.router.navigate(
            //       `/campaigns-review/${props?.router?.params?.id}`
            //     )
            //   :
            dispatch(
              getCampaignDetailsThunk(props?.router?.params?.id, false, true)
            );

          const cb2 = (data: any) => {
            setIsLoading(true);
            data?.[0]?.path &&
              dispatch(
                updateReviewCampaignThunk(
                  props?.router?.params?.id,
                  { attachments: [...selectedFiles, data?.[0]?.path] },
                  cb3,
                  isRejected
                )
              ).then(() => {
                setIsLoading(false);
              });
          };

          const formData = new FormData();
          formData.append("cspMedia", files[0]);
          dispatch(msgFileUploadThunk(formData, true, cb2)).then(() => {
            setIsLoading(false);
          });
        }
      };

      cb(files);
    }
  };

  const handleDownloadImg = (path: string) => {
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      let link = document.createElement("a");
      link.href = temp2;
      link.download = img?.name || "img.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    dispatch(getMsgFileThunk(path, cb));
  };

  const handleViewImg = (path: string) => {
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      if (img?.type.startsWith("video/")) {
        setIsOpenVideoLightBox(true);
      } else if (img?.type === "application/pdf") {
        setIsOpenDocLightBox(true);
      } else if (
        img?.type === "text/plain" ||
        img?.type === "text/html" ||
        img?.type === "application/json"
      ) {
        setIsOpenDocLightBox(true);
      } else {
        setIsOpenImgLightBox(true);
      }
    };
    dispatch(getMsgFileThunk(path, cb));
  };

  const handleDeleteImg = ({ onClose, deleteId }: any) => {
    const temp = [...selectedFiles]?.filter((dt) => dt !== deleteId);
    submitLoadingSubject.next(true);
    const cb = () => {
      onClose();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns-review/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false, true));
    };

    dispatch(
      updateReviewCampaignThunk(
        props?.router?.params?.id,
        { attachments: temp },
        cb,
        isRejected
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleUpdateConf = (updates: any) => {
    const cb = () => {
      setIsAttributeUpdating(false);
      setIsEditMode(false);
      setAttributeChanges({});
      onCloseSubject.next({});
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false, true));
    };

    dispatch(
      updateReviewCampaignThunk(
        props?.router?.params?.id,
        updates,
        cb,
        isRejected
      )
    );
  };

  const handleUpdateCampaign = (obj: any, cb?: any) => {
    const cb2 = () => {
      cb && cb();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns-review/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false, true));
    };
    dispatch(
      updateReviewCampaignThunk(props?.router?.params?.id, obj, cb2, isRejected)
    );
  };

  const handleUpdateCampaign2 = ({ obj, cb }: any) => {
    submitLoadingSubject.next(true);
    const cb2 = () => {
      cb();
      // isRejected
      //   ? props.router.navigate(
      //       `/campaigns-review/${props?.router?.params?.id}`
      //     )
      //   :
      dispatch(getCampaignDetailsThunk(props?.router?.params?.id, false, true));
    };
    dispatch(
      updateReviewCampaignThunk(props?.router?.params?.id, obj, cb2, isRejected)
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleSendReviewCampaign = () => {
    dispatch(
      openModal({
        path: "SendReviewCampaign",
        data: {
          title: "Resubmit Campaign for Review",
          bodyDesc:
            "Are you sure you want to send this campaign for review? Please add a message below explaining the changes made.",
          footer: false,
          reviewSubmit,
        },
      })
    );
  };

  const reviewSubmit = ({ values, resetForm }: any) => {
    submitLoadingSubject.next(true);
    const cb = () => {
      onCloseSubject.next({});
      resetForm();
      props.router.navigate(`/campaigns-review/${props?.router?.params?.id}`);
    };
    dispatch(
      nudgeReviewCampaignThunk(
        props?.router?.params?.id,
        {
          nudgeReason: values?.description,
        },
        cb
      )
    ).then(() => {
      submitLoadingSubject.next(false);
    });
  };

  const handleAttributeChange = (key: string, value: boolean) => {
    setAttributeChanges((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSaveAttributes = () => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to save these changes?",
        data: {
          title: "Save Changes",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Save",
          },
          onSubmit: () => {
            setIsAttributeUpdating(true);
            submitLoadingSubject.next(true);
            handleUpdateConf(attributeChanges);
            submitLoadingSubject.next(false);
            onCloseSubject.next({});
          },
        },
      })
    );
  };

  // Add handler to prevent clicks when not in edit mode
  const handleRadioClick = (e: React.MouseEvent) => {
    if (!isEditMode) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handlePopoverSubmit = (values: any, resetForm: any) => {
    dispatch(
      openModal({
        modalbody: "Are you sure you want to save these changes?",
        data: {
          title: "Save Changes",
          footer: true,
          cancelBtn: true,
          buttonText: {
            submit: "Save",
          },
          onSubmit: () => {
            submitLoadingSubject.next(true);
            const cb = () => {
              onCloseSubject.next({});
              resetForm();
            };
            handleUpdateCampaign2({ obj: values, cb });
          },
        },
      })
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        {(isOpenDocLightBox || isOpenVideoLightBox) && (
          <div
            className="position-fixed top-0 end-0 bottom-0 start-0"
            style={{
              zIndex: 1000,
              // backgroundColor: "rgba(0,0,0,0.5)",
              backgroundColor: "white",
            }}
          >
            <div
              className="position-absolute text-white cursor-pointer"
              style={{
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                borderRadius: "50%",
                padding: "5px 10px",
                right: 10,
                top: 40,
              }}
            >
              <i
                className="ri-close-circle-fill cursor-pointer fs-5xl text-white"
                onClick={() => {
                  setIsOpenDocLightBox(false);
                  setIsOpenVideoLightBox(false);
                  setPrevSrc("");
                }}
              />
            </div>
            {isOpenVideoLightBox ? (
              <video
                // playbackRate={2}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
                height="100%"
                width="100%"
              >
                <source src={prevSrc} type={`video/mp4`} />
              </video>
            ) : (
              <iframe
                id="iframe"
                title={prevSrc}
                src={prevSrc}
                height="100%"
                width="100%"
                style={{ background: "rgba(0, 0, 0, 0.6)" }}
              />
            )}
          </div>
        )}
        <Container fluid>
          <BreadCrumb
            title={props?.router?.params?.id}
            isBack={true}
            backClick={() => props.router.navigate("/campaigns")}
            isFilter={isRejected}
            filterButton={
              <Button
                onClick={handleSendReviewCampaign}
                className="btn btn-outline-primary"
              >
                Send for Review
              </Button>
            }
          />
          <div className="position-relative wrapper">
            {isInit ? (
              <div className={``}>
                <img
                  src={Loader}
                  className={`position-absolute top-50 start-50 translate-middle`}
                  alt="loading"
                />
              </div>
            ) : (
              <>
                {isRejected && campaignDetails?.rejectionReason && (
                  <div
                    className="bg-primary-subtle"
                    style={{ padding: 10, margin: "0 0 20px" }}
                  >
                    <span className="mb-0 fw-semibold">Rejection Reason:</span>{" "}
                    {campaignDetails?.rejectionReason}
                  </div>
                )}

                <Card>
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Brand Name:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.brandName ||
                                campaignDetails?.brand?.displayName ||
                                "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Use-Case:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.usecase || "-"}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">TCR Status:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4 text-secondary">
                              <b>Pending </b>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Migration:</h6>
                          </Col>
                          <Col md={8}>-</Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Group Id:</h6>
                          </Col>
                          <Col md={8}>{campaignDetails?.groupId || "-"}</Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Subgroups:</h6>
                          </Col>
                          <Col md={8}>
                            <div className="d-flex flex-wrap mt-1 mt-sm-0">
                              {campaignDetails?.subGroupId?.length > 0
                                ? campaignDetails?.subGroupId?.map(
                                    (dt: string, i2: number) => {
                                      return (
                                        <span className="details-tag" key={i2}>
                                          {dt}
                                        </span>
                                      );
                                    }
                                  )
                                : "-"}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Campaign and Content Attributes */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">
                      Campaign and Content Attributes
                    </h6>
                    <div className="d-flex gap-2">
                      {isEditMode ? (
                        <>
                          <Button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setIsEditMode(false);
                              setAttributeChanges({});
                            }}
                            disabled={isAttributeUpdating}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            onClick={handleSaveAttributes}
                            disabled={
                              Object.keys(attributeChanges).length === 0 ||
                              isAttributeUpdating
                            }
                          >
                            {isAttributeUpdating ? (
                              <>
                                <Spinner
                                  size="sm"
                                  animation="border"
                                  className="me-2"
                                />
                                Updating...
                              </>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={() => setIsEditMode(true)}
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-in
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptinYes"
                                      label="Yes"
                                      name="subscriberOptin"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            true
                                          : campaignDetails?.subscriberOptin ===
                                            true
                                      }
                                      onChange={() => {
                                        if (isEditMode) {
                                          handleAttributeChange(
                                            "subscriberOptin",
                                            true
                                          );
                                        }
                                      }}
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptinNo"
                                      label="No"
                                      name="subscriberOptin"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqSubscriberOptin
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptin"
                                        )
                                          ? attributeChanges.subscriberOptin ===
                                            false
                                          : campaignDetails?.subscriberOptin ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptin",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {campaignDetails?.subscriberOptin ? (
                                      <HoverPopover
                                        icon={
                                          <i
                                            className={`ri-eye-fill align-middle fs-lg cursor-pointer ${
                                              isAttributeUpdating
                                                ? "text-muted"
                                                : "text-secondary"
                                            }`}
                                          />
                                        }
                                      >
                                        <Form
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation1.handleSubmit();
                                            if (
                                              validation1.isValid &&
                                              Object.keys(validation1.touched)
                                                .length > 0
                                            ) {
                                              handlePopoverSubmit(
                                                validation1.values,
                                                validation1.resetForm
                                              );
                                            }
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinKeywords">
                                              Keywords
                                            </Form.Label>
                                            <Form.Control
                                              type="text"
                                              id="optinKeywords"
                                              placeholder="Enter Keywords"
                                              disabled={
                                                !isEditMode ||
                                                isAttributeUpdating
                                              }
                                              {...validation1.getFieldProps(
                                                "optinKeywords"
                                              )}
                                            />
                                            {validation1.touched
                                              .optinKeywords &&
                                            validation1.errors.optinKeywords ? (
                                              <Form.Text className="text-danger">
                                                {
                                                  validation1.errors
                                                    .optinKeywords
                                                }
                                              </Form.Text>
                                            ) : null}
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optinMessage">
                                              Message
                                            </Form.Label>
                                            <Form.Control
                                              as="textarea"
                                              rows={3}
                                              id="optinMessage"
                                              placeholder="Enter Message"
                                              disabled={
                                                !isEditMode ||
                                                isAttributeUpdating
                                              }
                                              {...validation1.getFieldProps(
                                                "optinMessage"
                                              )}
                                            />
                                            {validation1.touched.optinMessage &&
                                            validation1.errors.optinMessage ? (
                                              <Form.Text className="text-danger">
                                                {
                                                  validation1.errors
                                                    .optinMessage
                                                }
                                              </Form.Text>
                                            ) : null}
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={
                                              !isEditMode ||
                                              loading2 ||
                                              isAttributeUpdating
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                                className="me-2"
                                              />
                                            )}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Opt-Out{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberOptoutYes"
                                      label="Yes"
                                      name="subscriberOptout"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            true
                                          : campaignDetails?.subscriberOptout ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberOptoutNo"
                                      label="No"
                                      name="subscriberOptout"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqSubscriberOptout
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberOptout"
                                        )
                                          ? attributeChanges.subscriberOptout ===
                                            false
                                          : campaignDetails?.subscriberOptout ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberOptout",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {campaignDetails?.subscriberOptout ? (
                                      <HoverPopover>
                                        <Form
                                          action="#"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation2.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutKeywords">
                                              Type Opt-out Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation2.values
                                                  .optoutKeywords
                                                  ? validation2.values.optoutKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation2.setFieldValue(
                                                  "optoutKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation2.touched
                                                ?.optoutKeywords &&
                                              validation2.errors
                                                ?.optoutKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation2.errors
                                                      ?.optoutKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="optoutMessage">
                                              Type Opt-out Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="optoutMessage"
                                              name="optoutMessage"
                                              onChange={
                                                validation2.handleChange
                                              }
                                              onBlur={validation2.handleBlur}
                                              value={
                                                validation2.values
                                                  .optoutMessage || ""
                                              }
                                              className={`form-control ${
                                                validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation2.touched
                                                  ?.optoutMessage &&
                                                validation2.errors
                                                  ?.optoutMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation2.errors
                                                        ?.optoutMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation2.values
                                                    .optoutMessage?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={
                                              !isEditMode ||
                                              loading2 ||
                                              isAttributeUpdating
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Subscriber Help
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="subscriberHelpYes"
                                      label="Yes"
                                      name="subscriberHelp"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            true
                                          : campaignDetails?.subscriberHelp ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="subscriberHelpNo"
                                      label="No"
                                      name="subscriberHelp"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqSubscriberHelp
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "subscriberHelp"
                                        )
                                          ? attributeChanges.subscriberHelp ===
                                            false
                                          : campaignDetails?.subscriberHelp ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "subscriberHelp",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  >
                                    {campaignDetails?.subscriberHelp ? (
                                      <HoverPopover>
                                        <Form
                                          action="#"
                                          onSubmit={(e) => {
                                            e.preventDefault();
                                            validation3.handleSubmit();
                                          }}
                                        >
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpKeywords">
                                              Type Help Keywords here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <TagsInput
                                              inputProps={{
                                                placeholder: "Add a keywords",
                                              }}
                                              value={
                                                validation3.values.helpKeywords
                                                  ? validation3.values.helpKeywords.split(
                                                      ","
                                                    )
                                                  : []
                                              }
                                              onChange={(e: any) => {
                                                validation3.setFieldValue(
                                                  "helpKeywords",
                                                  e.toString()
                                                );
                                              }}
                                              addOnBlur={true}
                                              onlyUnique={true}
                                            />
                                            <div>
                                              {validation3.touched
                                                ?.helpKeywords &&
                                              validation3.errors
                                                ?.helpKeywords ? (
                                                <Form.Control.Feedback
                                                  type="invalid"
                                                  className="d-block"
                                                >
                                                  {
                                                    validation3.errors
                                                      ?.helpKeywords
                                                  }
                                                </Form.Control.Feedback>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="mb-3">
                                            <Form.Label htmlFor="helpMessage">
                                              Type Help Message here{" "}
                                              <span className="text-primary">
                                                *
                                              </span>
                                            </Form.Label>
                                            <textarea
                                              id="helpMessage"
                                              name="helpMessage"
                                              onChange={
                                                validation3.handleChange
                                              }
                                              onBlur={validation3.handleBlur}
                                              value={
                                                validation3.values
                                                  .helpMessage || ""
                                              }
                                              className={`form-control ${
                                                validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors?.helpMessage
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              rows={3}
                                              maxLength={4096}
                                              minLength={40}
                                            />
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div>
                                                {validation3.touched
                                                  ?.helpMessage &&
                                                validation3.errors
                                                  ?.helpMessage ? (
                                                  <Form.Control.Feedback
                                                    type="invalid"
                                                    className="d-block"
                                                  >
                                                    {
                                                      validation3.errors
                                                        ?.helpMessage
                                                    }
                                                  </Form.Control.Feedback>
                                                ) : null}
                                              </div>
                                              <div style={{ flex: "none" }}>
                                                {
                                                  validation3.values.helpMessage
                                                    ?.length
                                                }{" "}
                                                / 320
                                              </div>
                                            </div>
                                          </div>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                            className="d-flex m-auto"
                                            disabled={
                                              !isEditMode ||
                                              loading2 ||
                                              isAttributeUpdating
                                            }
                                          >
                                            {loading2 && (
                                              <Spinner
                                                size="sm"
                                                animation="border"
                                              />
                                            )}{" "}
                                            Update
                                          </Button>
                                        </Form>
                                      </HoverPopover>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Number Pooling
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="numberPoolYes"
                                      label="Yes"
                                      name="numberPool"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool === true
                                          : campaignDetails?.numberPool === true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="numberPoolNo"
                                      label="No"
                                      name="numberPool"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqNumberPool
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "numberPool"
                                        )
                                          ? attributeChanges.numberPool ===
                                            false
                                          : campaignDetails?.numberPool ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "numberPool",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Direct Lending or Loan Arrangement
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="directLendingYes"
                                      label="Yes"
                                      name="directLending"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "directLending"
                                        )
                                          ? attributeChanges.directLending ===
                                            true
                                          : campaignDetails?.directLending ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "directLending",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="directLendingNo"
                                      label="No"
                                      name="directLending"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.reqDirectLending
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "directLending"
                                        )
                                          ? attributeChanges.directLending ===
                                            false
                                          : campaignDetails?.directLending ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "directLending",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                      <Col lg={6}>
                        <ul className="list-group list-group-flush">
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Link
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedLinkYes"
                                      label="Yes"
                                      name="embeddedLink"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            true
                                          : campaignDetails?.embeddedLink ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedLinkNo"
                                      label="No"
                                      name="embeddedLink"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.noEmbeddedLink
                                      }
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedLink"
                                        )
                                          ? attributeChanges.embeddedLink ===
                                            false
                                          : campaignDetails?.embeddedLink ===
                                            false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedLink",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Embedded Phone Number{" "}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="embeddedPhoneYes"
                                      label="Yes"
                                      name="embeddedPhone"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "embeddedPhone"
                                        )
                                          ? attributeChanges.embeddedPhone ===
                                            true
                                          : campaignDetails?.embeddedPhone ===
                                            true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          true
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="embeddedPhoneNo"
                                      label="No"
                                      name="embeddedPhone"
                                      value="No"
                                      disabled={
                                        !isEditMode
                                          ? true
                                          : campConditions?.noEmbeddedPhone
                                      }
                                      checked={
                                        campaignDetails?.embeddedPhone === false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange(
                                          "embeddedPhone",
                                          false
                                        )
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="list-group-item ps-0 pb-1"
                            onClick={handleRadioClick}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Age Gated
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="ageGatedYes"
                                      label="Yes"
                                      name="ageGated"
                                      value="Yes"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === true
                                          : campaignDetails?.ageGated === true
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange("ageGated", true)
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="ageGatedNo"
                                      label="No"
                                      name="ageGated"
                                      value="No"
                                      disabled={!isEditMode}
                                      checked={
                                        attributeChanges.hasOwnProperty(
                                          "ageGated"
                                        )
                                          ? attributeChanges.ageGated === false
                                          : campaignDetails?.ageGated === false
                                      }
                                      onChange={() =>
                                        isEditMode &&
                                        handleAttributeChange("ageGated", false)
                                      }
                                      onClick={handleRadioClick}
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item ps-0 pb-1">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <label className="form-check-label mb-0">
                                  <span className="d-block fw-semibold text-truncate">
                                    Terms & Conditions{" "}
                                  </span>
                                  <span className="text-muted d-block">
                                    {campaignTnC?.subTerms?.[0]}
                                  </span>
                                </label>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="me-4">
                                    <Radiobox
                                      id="termsAndConditionsYes"
                                      label="Yes"
                                      name="termsAndConditions"
                                      value="Yes"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        true
                                      }
                                    />
                                  </div>
                                  <div className="me-5">
                                    <Radiobox
                                      id="termsAndConditionsNo"
                                      label="No"
                                      name="termsAndConditions"
                                      value="No"
                                      disabled={true}
                                      checked={
                                        campaignDetails?.termsAndConditions ===
                                        false
                                      }
                                    />
                                  </div>
                                  <div
                                    className="d-flex align-items-start mb-2"
                                    style={{ width: 16, height: 24 }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Campaign Details */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Campaign Details</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditCampaignDetails",
                              data: {
                                title: "Campaign Details",
                                campaignDetails,
                                campaignDetailsSubmit,
                                isReviewPage: true,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Privacy Policy Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.privacyPolicyLink ? (
                                <a
                                  href={campaignDetails?.privacyPolicyLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.privacyPolicyLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Terms and Conditions Link:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.termsAndConditionsLink ? (
                                <a
                                  href={campaignDetails?.termsAndConditionsLink}
                                  target="_blank"
                                >
                                  {campaignDetails?.termsAndConditionsLink}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={6} className="mb-2">
                        <Row className="g-0">
                          <Col md={4}>
                            <h6 className="mb-0">Website:</h6>
                          </Col>
                          <Col md={8}>
                            <p className="mb-0 me-md-4">
                              {campaignDetails?.website ? (
                                <a
                                  href={campaignDetails?.website}
                                  target="_blank"
                                >
                                  {campaignDetails?.website}
                                </a>
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={2}>
                        <h6 className="mb-0">Campaign Description:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0 me-md-4">
                          {campaignDetails?.description}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <h6 className="mb-0">Call-to-Action / Message Flow:</h6>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0">{campaignDetails?.messageFlow}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                {/* Sample Messages */}
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-between">
                    <h6 className="card-title mb-2">Sample Messages</h6>
                    <div className="d-flex">
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          dispatch(
                            openModal({
                              path: "EditSampleMessages",
                              data: {
                                title: "Sample Messages",
                                campaignDetails,
                                sampleMessagesSubmit,
                                campaignTerms,
                                isReviewPage: true,
                                footer: false,
                              },
                            })
                          );
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {campaignDetails?.sample1 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          1
                        </span>
                        {campaignDetails?.sample1}
                      </p>
                    ) : null}
                    {campaignDetails?.sample2 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          2
                        </span>
                        {campaignDetails?.sample2}
                      </p>
                    ) : null}
                    {campaignDetails?.sample3 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          3
                        </span>
                        {campaignDetails?.sample3}
                      </p>
                    ) : null}
                    {campaignDetails?.sample4 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          4
                        </span>
                        {campaignDetails?.sample4}
                      </p>
                    ) : null}
                    {campaignDetails?.sample5 ? (
                      <p>
                        <span className="badge bg-primary-subtle text-primary me-2 fs-md">
                          5
                        </span>
                        {campaignDetails?.sample5}
                      </p>
                    ) : null}
                  </Card.Body>
                </Card>

                {/* Sample Multimedia */}
                <Card>
                  <Card.Header>
                    <h6 className="card-title mb-2">Sample Multimedia</h6>
                  </Card.Header>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col lg={6}>
                        <Dropzone
                          onDrop={(acceptedFiles: any, fileRejections: any) => {
                            if (selectedFiles?.length > 4) {
                              toast.error(
                                "Max 5 uploads per campaign is allowed"
                              );
                            } else {
                              fileRejections?.length > 0 &&
                                console.log("fileRejections", fileRejections);
                              fileRejections?.forEach((file: any) => {
                                file.errors.forEach(
                                  (err: { code: string; message: string }) => {
                                    if (err.code === "file-too-large") {
                                      // toast.error("File is larger than 10MB");
                                    }

                                    if (err.code === "file-invalid-type") {
                                      toast.error(
                                        err.message || "Something went wrong"
                                      );
                                    }
                                  }
                                );
                              });
                              handleAcceptedFiles(
                                acceptedFiles,
                                fileRejections
                              );
                            }
                          }}
                          maxSize={10485760} // 10mb
                          multiple={false}
                          // accept={{
                          //   "image/*": [],
                          //   "video/*": [],
                          //   "audio/*": [],
                          // }}
                          accept={`.3ga, .3gp, .amr, .avi, .bmp, .bz2, .cal, .css, .csv, .dib, .flac,
                            .flv, .gif, .gz, .ico, .jpeg, .jpg, .js,  .m1a, .m1v, .m2a,
                            .m4a, .m4b, .m4p, .m4r, .m4v, .mov, .mp1, .mp2, .mp3, .mp4, .mpa,
                            .mpeg, .mpg, .mpv, .oga, .ogg, .ogm, .ogv, .ogx, .pdf, .pjpeg, .png,
                            .qt, .rtf, .smil, .spx, .svg, .tar, .tif, .tiff, .txt, .vcard, .vcf,
                            .wap, .wav, .webm, .webp, .wmv, .xml, .zip`}
                        >
                          {({ getRootProps }: any) => (
                            <div className="position-relative dropzone dz-clickable text-center border rounded">
                              {isLoading ? (
                                <div className="d-flex justify-content-center align-items-center position-absolute start-0 end-0 top-0 bottom-0 bg-dark bg-opacity-50">
                                  <img
                                    src={Loader2}
                                    alt="loading"
                                    className="img-thumbnail border-0 bg-transparent"
                                  />
                                </div>
                              ) : null}
                              <div
                                className="dz-message needsclick cursor-pointer"
                                {...getRootProps()}
                              >
                                <div className="mb-3">
                                  <i className="ri-camera-fill text-muted fs-8xl" />
                                  <i className="ri-video-fill text-muted fs-8xl" />
                                  <i className="ri-file-music-fill text-muted fs-8xl" />
                                </div>
                                <p className="fs-lg">
                                  Drop a multimedia file to upload or click to
                                  upload
                                </p>
                                <p className="fs-md text-muted">
                                  Maximum upload file size: 10MB
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Col>
                      <Col lg={6}>
                        <div className="border rounded p-3 dropzone flex-column">
                          <h6 className="mb-3">Sample Multimedia Files</h6>
                          <ul className="vstack gap-2 list-unstyled mb-0">
                            {selectedFiles?.map((dt: string, i: number) => (
                              <li key={i}>
                                <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        <i className="ri ri-file-line fs-lg"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-0">{dt || ""}</h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !loading2 && handleDownloadImg(dt);
                                        }}
                                      />
                                      <i
                                        className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !loading2 && handleViewImg(dt);
                                        }}
                                      />
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          if (!loading2) {
                                            dispatch(
                                              openModal({
                                                modalbody:
                                                  "Are you sure you want to delete this file?",
                                                data: {
                                                  title: "Delete File",
                                                  footer: true,
                                                  cancelBtn: true,
                                                  buttonText: {
                                                    submit: "Continue",
                                                  },
                                                  onCancel: ({
                                                    onClose,
                                                  }: any) => {
                                                    onClose();
                                                  },
                                                  onSubmit: ({
                                                    onClose,
                                                  }: any) => {
                                                    handleDeleteImg({
                                                      onClose,
                                                      deleteId: dt,
                                                    });
                                                  },
                                                },
                                              })
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CampaignReviewDetails);
