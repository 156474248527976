import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Spinner,
  Container,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Datatable from "../../Common/Datatable";
import {
  getAllMessageLogsThunk,
  setAllMessageLogsAttachmentsThunk,
} from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import Loader from "assets/images/spinner-dark.svg";
import Flatpickr from "react-flatpickr";
import {
  asyncBrandList,
  asyncCampaignList,
  asyncSubgroupList,
  capitalizeString,
  dateFormat,
  dateRangeArr,
  formatBytes,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { customSelectTheme } from "helpers/common";
import withRouter from "Common/withRouter";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import Select from "react-select";
import Tooltip from "rc-tooltip";
import { getMmsFileApi } from "helpers/apiroute_helper";
import { GroupSelect } from "Common/filter/group-select";
import { BrandSelect } from "Common/filter/brand-select";

const selectedTypeArr = ["All", "SMS", "MMS"];
const selectedTypeArr2 = ["All", "Outbound", "Inbound"];
const phoneNumberWithPlus = /^\+\w+/;

interface AttachmentType {
  id: string;
  fileSize: number;
}

interface MediaURLType {
  url: string;
  size: number;
}

interface RowType {
  _id: string;
  type: string;
  attachments?: AttachmentType[];
  mediaURL?: string[];
}

interface AttachmentsState {
  [key: string]: string[];
}

interface URLsState {
  [key: string]: File[];
}

interface AttachmentsPopoverProps {
  row: RowType;
}

// Cache object outside the component to persist across renders
const attachmentsCache: {
  [key: string]: {
    attachments?: string[];
    urls?: File[];
  };
} = {};

const AttachmentsPopover: React.FC<AttachmentsPopoverProps> = ({ row }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<AttachmentsState>({});
  const [urls, setUrls] = useState<URLsState>({});
  const [hoverId, setHoverId] = useState<string>("");
  const hoverTimeoutRef = useRef<NodeJS.Timeout>();
  const isLoadingRef = useRef<boolean>(false);

  const loadAttachments = async (): Promise<void> => {
    // Return if no row ID or if already loading
    if (!row._id || isLoadingRef.current) return;

    // Check cache first
    const cachedData = attachmentsCache[row._id];
    if (cachedData) {
      if (cachedData.attachments) {
        setAttachments((prev: any) => ({
          ...prev,
          [row._id]: cachedData.attachments,
        }));
      }
      if (cachedData.urls) {
        setUrls((prev) => ({ ...prev, [row._id]: cachedData.urls! }));
      }
      return;
    }

    // Set loading ref to prevent multiple simultaneous loads
    isLoadingRef.current = true;

    const getMediaFile = async (): Promise<void> => {
      setIsLoading(true);
      try {
        const promises = row?.attachments?.map(async (dt: AttachmentType) => {
          return await getMmsFileApi(dt.id);
        });
        const results = await Promise.all(promises || []);
        const urlObjects = results?.map((dt: any) => URL.createObjectURL(dt));

        setAttachments((prev) => ({
          ...prev,
          [row._id]: urlObjects,
        }));

        // Cache the results
        attachmentsCache[row._id] = {
          ...attachmentsCache[row._id],
          attachments: urlObjects,
        };
      } catch (error) {
        console.error("Error loading attachments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const getFileFromUrl = async (url: string): Promise<File> => {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      return new File([blob], url.substring(url.lastIndexOf("/") + 1), {
        type: blob.type,
      });
    };

    const loadMediaUrls = async (): Promise<void> => {
      setIsLoading2(true);
      try {
        const promises = row?.mediaURL?.map(async (url: string) => {
          return await getFileFromUrl(url);
        });
        const results = await Promise.all(promises || []);

        setUrls((prev) => ({
          ...prev,
          [row._id]: results,
        }));

        // Cache the results
        attachmentsCache[row._id] = {
          ...attachmentsCache[row._id],
          urls: results,
        };
      } catch (error) {
        console.error("Error loading media URLs:", error);
      } finally {
        setIsLoading2(false);
      }
    };

    try {
      if (row.attachments?.length && !attachments[row._id]) {
        await getMediaFile();
      }
      if (row.mediaURL?.length && !urls[row._id]) {
        await loadMediaUrls();
      }
    } finally {
      isLoadingRef.current = false;
    }
  };

  const handleMouseEnter = (): void => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setShowPopover(true);
    hoverTimeoutRef.current = setTimeout(() => {
      loadAttachments();
    }, 500);
  };

  const handleMouseLeave = (): void => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    setShowPopover(false);
  };

  useEffect(() => {
    return () => {
      if (hoverTimeoutRef.current) {
        clearTimeout(hoverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        show={showPopover}
        placement="top"
        overlay={
          <Popover
            id="popover-positioned-top"
            className="media-popover"
            style={{
              maxWidth: "400px",
              width: "auto",
            }}
          >
            <Popover.Body className="p-3">
              <div className="media-container">
                {/* Media URLs Section */}
                {(row?.mediaURL?.length ?? 0) > 0 && (
                  <div className="media-section">
                    {isLoading2 ? (
                      <div className="loader-wrapper">
                        <img
                          src={Loader}
                          alt="loading"
                          className="loader-image"
                        />
                      </div>
                    ) : urls[row._id] ? (
                      <div className="media-grid">
                        {row.mediaURL?.map((url: string, i: number) => (
                          <div
                            key={`url-${i}`}
                            className="media-item"
                            onMouseEnter={() => setHoverId(`url-${i}`)}
                            onMouseLeave={() => setHoverId("")}
                          >
                            <a
                              href={url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="media-link"
                            >
                              <img
                                src={url}
                                alt={`Media ${i + 1}`}
                                className="media-image"
                              />
                              {hoverId === `url-${i}` && (
                                <div className="media-overlay">
                                  <span className="file-size">
                                    {formatBytes(urls[row._id]?.[i]?.size || 0)}
                                  </span>
                                  <i className="bi bi-download download-icon"></i>
                                </div>
                              )}
                            </a>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="placeholder-content">
                        Hover to load media...
                      </div>
                    )}
                  </div>
                )}

                {/* Attachments Section */}
                {(row?.attachments?.length ?? 0) > 0 && (
                  <div className="media-section">
                    {isLoading ? (
                      <div className="loader-wrapper">
                        <img
                          src={Loader}
                          alt="loading"
                          className="loader-image"
                        />
                      </div>
                    ) : attachments[row._id] ? (
                      <div className="media-grid">
                        {row.attachments?.map(
                          (att: AttachmentType, i: number) => (
                            <div
                              key={`att-${i}`}
                              className="media-item"
                              onMouseEnter={() => setHoverId(`att-${i}`)}
                              onMouseLeave={() => setHoverId("")}
                            >
                              <a
                                href={attachments[row._id]?.[i]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media-link"
                              >
                                <img
                                  src={attachments[row._id]?.[i]}
                                  alt={`Attachment ${i + 1}`}
                                  className="media-image"
                                />
                                {hoverId === `att-${i}` && (
                                  <div className="media-overlay">
                                    <span className="file-size">
                                      {formatBytes(att.fileSize || 0)}
                                    </span>
                                    <i className="bi bi-download download-icon"></i>
                                  </div>
                                )}
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    ) : (
                      <div className="placeholder-content">
                        Hover to load attachments...
                      </div>
                    )}
                  </div>
                )}

                {/* No Media Available Message */}
                {!row.mediaURL?.length && !row.attachments?.length && (
                  <div className="no-media">No Media Available</div>
                )}
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="gallery-icon-wrapper position-relative d-inline-block">
          <i className="bi bi-images align-middle text-secondary fs-4">
            {(row.mediaURL?.length || 0) + (row.attachments?.length || 0) >
              0 && (
              <span
                className="media-count-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
                style={{
                  fontSize: "0.65rem",
                  padding: "0.25em 0.45em",
                  transform: "translate(25%, -25%)",
                  border: "2px solid #fff",
                }}
              >
                {(row.mediaURL?.length || 0) + (row.attachments?.length || 0) >
                9
                  ? "9+"
                  : (row.mediaURL?.length || 0) +
                    (row.attachments?.length || 0)}
              </span>
            )}
          </i>
        </div>
      </OverlayTrigger>
    </div>
  );
};

const ShortLinksPopover = ({ row }: any) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleMouseEnter = (): void => {
    setShowPopover(true);
  };

  const handleMouseLeave = (): void => {
    setShowPopover(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        show={showPopover}
        placement="top"
        overlay={
          <Popover
            id="popover-positioned-top"
            className="media-popover"
            style={{
              maxWidth: "400px",
              width: "auto",
            }}
          >
            <Popover.Body className="p-3">
              <ul
                className="vstack gap-2 list-unstyled mb-0"
                style={{ maxHeight: 200, overflow: "auto" }}
              >
                {row.clickLog?.map((dt: any, i: number) => (
                  <li key={i}>
                    <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <a
                            href={dt?.shortLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mb-0 text-break"
                          >
                            {dt?.shortLink}
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="gallery-icon-wrapper position-relative d-inline-block">
          <i className="bi bi-link-45deg align-middle text-secondary fs-4">
            <span
              className="media-count-badge position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
              style={{
                fontSize: "0.65rem",
                padding: "0.25em 0.45em",
                transform: "translate(25%, -25%)",
                border: "2px solid #fff",
              }}
            >
              {(row.clickLog?.length || 0) > 9
                ? "9+"
                : row.clickLog?.length || 0}
            </span>
          </i>
        </div>
      </OverlayTrigger>
    </div>
  );
};

const MessageLogsList = (props: any) => {
  document.title = "Signal House Portal Messaging Logs";

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(selectedTypeArr[0]);
  const [selectedType2, setSelectedType2] = useState(selectedTypeArr2[0]);
  const [datePickerModal, setDatePickerModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dateRangeTemp, setDateRangeTemp] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [hoverId, setHoverId] = useState<string>("");

  const selectData = createSelector(
    (state: any) => state.Messages,
    (state: any) => state.Groups,
    (messages, groups) => ({
      loading2: messages.loading2,
      groupDetails: groups.AllGroups,
      allMessageLogs: messages.allMessageLogs,
      allMessageLogsAttachments: messages.allMessageLogsAttachments,
      allMessageLogsUrls: messages.allMessageLogsUrls,
    })
  );

  const {
    loading2,
    groupDetails,
    allMessageLogs,
    allMessageLogsAttachments,
    allMessageLogsUrls,
  } = useSelector(selectData);

  const columns = [
    {
      name: <span className="font-weight-bold fs-sm">Date and Time</span>,
      minWidth: "220px",
      selector: (row: { CreatedDate: string }) => row.CreatedDate,
      cell: (row: { CreatedDate: string }) => (
        <span data-tag="allowRowEvents" className="d-flex align-items-center">
          <i
            data-tag="allowRowEvents"
            className="bi bi-calendar3 me-2 fs-lg text-secondary"
          />
          {dateFormat(row.CreatedDate, "MM/DD/YYYY, hh:mm:ss A")}
        </span>
      ),
      sortable: true,
      sortFunction: (a: any, b: any) => {
        return (
          new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime()
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Direction</span>,
      minWidth: "150px",
      selector: (cell: { direction: any }) => cell.direction,
      cell: (cell: { direction: any }) => {
        switch (cell.direction?.toLowerCase()) {
          case "mt":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-info text-info"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-warning text-warning"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "inbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          case "outbound-api":
          case "outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-api", ""))}
              </span>
            );
          case "group-outbound":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {capitalizeString(cell.direction.replace("-", " "))}
              </span>
            );
          case "failed":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-primary border border-primary text-primary"
              >
                {capitalizeString(cell.direction)}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-danger text-danger"
              >
                {capitalizeString(cell.direction) || "N/A"}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => a.direction.localeCompare(b.direction),
    },
    {
      name: <span className="font-weight-bold fs-sm">Type</span>,
      minWidth: "110px",
      selector: (row: { type: string }) => row.type,
      cell: (row: { type: string }) => {
        switch (row.type) {
          case "SMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-black text-black"
              >
                {row.type}
              </span>
            );
          case "MMS":
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary-subtle text-muted"
              >
                {row.type}
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="badge bg-body-secondary border border-secondary text-secondary"
              >
                {row.type}
              </span>
            );
        }
      },
      sortable: true,
      sortFunction: (a: any, b: any) => a.type.localeCompare(b.type),
    },
    {
      name: <span className="font-weight-bold fs-sm">From</span>,
      minWidth: "130px",
      selector: (row: { sendersPhoneNumber: number | string }) =>
        row.sendersPhoneNumber,
      sortable: true,
      sortFunction: (a: any, b: any) =>
        a.sendersPhoneNumber.localeCompare(b.sendersPhoneNumber),
    },
    {
      name: <span className="font-weight-bold fs-sm">To</span>,
      minWidth: "130px",
      selector: (row: { recieversPhoneNumber?: number | string }) =>
        row.recieversPhoneNumber,
      cell: (row: { recieversPhoneNumber?: number | string }) => {
        let phoneNumbers = row?.recieversPhoneNumber?.toString();
        let phoneArray = phoneNumbers
          ? phoneNumbers.split(",").map((num) => num.trim())
          : [];

        const maxInitialDisplay = 1;
        const hasMoreNumbers = phoneArray.length > maxInitialDisplay;

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {phoneArray.slice(0, maxInitialDisplay).map((num, index) => (
              <span key={index}>{num}</span>
            ))}
            {hasMoreNumbers && (
              <>
                <span
                  className="btn btn-link p-0"
                  style={{
                    fontSize: "0.85rem",
                    cursor: "pointer",
                    color: "black",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  and {phoneArray.length - 1} more
                </span>
                {showModal && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.09)",
                        zIndex: 1040,
                      }}
                      onClick={() => setShowModal(false)}
                    />
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      backdrop={false}
                      style={{ zIndex: 1050 }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Phone Numbers</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {phoneArray.map((num, index) => (
                          <div key={index}>{num}</div>
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                )}
              </>
            )}
          </div>
        );
      },
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aNumbers = a.recieversPhoneNumber?.toString().split(",")[0] || "";
        const bNumbers = b.recieversPhoneNumber?.toString().split(",")[0] || "";
        return aNumbers.localeCompare(bNumbers);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Body</span>,
      selector: (row: { messageBody: string }) => row.messageBody,
      cell: (row: { messageBody: string }) => (
        <Tooltip
          placement="top"
          overlayClassName={row.messageBody.length > 1000 ? "largeTooltip" : ""}
          overlay={
            <p className="mb-0" style={{ wordBreak: "break-word" }}>
              {row.messageBody}
            </p>
          }
        >
          <i className="ri-eye-fill align-middle text-secondary fs-lg cursor-pointer"></i>
        </Tooltip>
      ),
    },
    {
      name: <span className="font-weight-bold fs-sm">Attachments</span>,
      minWidth: "125px",
      selector: (row: { mediaURL: any; attachments: any }) =>
        row.attachments || row.mediaURL,
      cell: (row: {
        type: string;
        mediaURL: any;
        attachments: any;
        _id: string;
      }) => (row.type === "MMS" ? <AttachmentsPopover row={row} /> : "-"),
    },
    {
      name: <span className="font-weight-bold fs-sm">Short Links</span>,
      minWidth: "125px",
      selector: (row: { clickLog: any }) => row.clickLog || [],
      cell: (row: { clickLog: any }) =>
        row?.clickLog?.length > 0 ? <ShortLinksPopover row={row} /> : "-",
    },
    {
      name: (
        <span className="font-weight-bold fs-sm d-flex align-items-center">
          Segments{" "}
          <Tooltip
            placement="bottomRight"
            overlay="Segments are calculated when a message is Sent. If a message fails prior to entering the “Sent” state, the message segments are not calculated."
          >
            <i className="ri-information-line text-secondary fs-2xl ms-2" />
          </Tooltip>
        </span>
      ),
      minWidth: "150px",
      selector: (row: { segments?: string }) => row?.segments || "-",
      sortable: true,
      sortFunction: (a: any, b: any) => {
        const aSegments = a.segments || "0";
        const bSegments = b.segments || "0";
        return parseInt(aSegments) - parseInt(bSegments);
      },
    },
    {
      name: <span className="font-weight-bold fs-sm">Status</span>,
      sortable: true,
      minWidth: "150px",
      selector: (cell: { latestStatus: any }) => cell.latestStatus,
      cell: (cell: { latestStatus: any }) => {
        switch (cell.latestStatus?.toLowerCase()) {
          case "queued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-info"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-info p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "enqueued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "dequeued":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#5c5c5c" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#787777" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "created":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center "
                style={{ color: "#787777" }}
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle p-1 me-2"
                  style={{ background: "#a8a5a5" }}
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "delivered":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "sent":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-black"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-black p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString(cell.latestStatus)}
              </span>
            );
          case "mo_message_received":
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-secondary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-secondary p-1 me-2"
                >
                  <span></span>
                </span>
                <b data-tag="allowRowEvents">
                  {capitalizeString(
                    cell.latestStatus.replace("mo_message_", "")
                  )}
                </b>
              </span>
            );
          default:
            return (
              <span
                data-tag="allowRowEvents"
                className="d-flex align-items-center text-primary"
              >
                <span
                  data-tag="allowRowEvents"
                  className="badge border border-2 border-white rounded-circle bg-primary p-1 me-2"
                >
                  <span></span>
                </span>
                {capitalizeString("failed")}
              </span>
            );
        }
      },
      sortFunction: (a: any, b: any) =>
        a.latestStatus.localeCompare(b.latestStatus),
    },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      signalHouseSID: "",
      from: "",
      to: "",
      subGroupId: [],
      brandId: [],
      campaignId: [],
      status: [],
      createdAt: "",
      startDate: "",
      endDate: "",
    },
    onSubmit: (values) => {
      setOpen(false);
      handleGetData(
        1,
        rowsPerPage,
        removeEmptyAndNullValues({
          ...values,
          subGroupId: values.subGroupId.length > 0 ? values.subGroupId : "",
          brandId:
            values.brandId.length > 0
              ? values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            values.campaignId.length > 0
              ? values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          status: values.status.length > 0 ? values.status : "",
        })
      );
    },
  });

  useEffect(() => {
    (selectedType || selectedType2) &&
      handleGetData(
        1,
        rowsPerPage,
        removeEmptyAndNullValues({
          ...validation.values,
          subGroupId:
            validation.values.subGroupId.length > 0
              ? validation.values.subGroupId
              : "",
          brandId:
            validation.values.brandId.length > 0
              ? validation.values.brandId?.map((dt: any) => dt.brandId)
              : "",
          campaignId:
            validation.values.campaignId.length > 0
              ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
              : "",
          status:
            validation.values.status.length > 0 ? validation.values.status : "",
        })
      );
  }, [selectedType, selectedType2]);

  const handleGetData = (page: number, perPage: number, otherParams?: any) => {
    const tempstartDate = otherParams.startDate
      ? moment(otherParams.startDate, "MM-DD-YYYY").startOf("day").toISOString()
      : "";
    const tempendDate = otherParams.endDate
      ? moment(otherParams.endDate, "MM-DD-YYYY").endOf("day").toISOString()
      : "";
    const formattedFrom = otherParams.from
      ? phoneNumberWithPlus.test(otherParams.from)
        ? `%2B${otherParams.from.split("+")[1]}`
        : otherParams.from
      : "";
    const formattedTo = otherParams.to
      ? phoneNumberWithPlus.test(otherParams.to)
        ? `%2B${otherParams.to.split("+")[1]}`
        : otherParams.to
      : "";

    const allParams = {
      ...otherParams,
      type: selectedType === "All" ? "" : selectedType,
      direction: selectedType2 === "All" ? "" : selectedType2.toLowerCase(),
      from: formattedFrom,
      to: formattedTo,
      createdAt: "",
      startDate: tempstartDate,
      endDate: tempendDate,
    };

    dispatch(
      getAllMessageLogsThunk(page, perPage, removeEmptyAndNullValues(allParams))
    );
  };

  const handleRowClick = (row: { type: string; signalHouseSID: string }) => {
    row.type === "SMS"
      ? navigate(`/sms-logs-details/${row.signalHouseSID}`)
      : navigate(`/mms-logs-details/${row.signalHouseSID}`);
  };

  const handlePageChange = (page: number) => {
    setCurrPage(page);
    handleGetData(
      page,
      rowsPerPage,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        status:
          validation.values.status.length > 0 ? validation.values.status : "",
      })
    );
  };

  const handleRowsPerPageChange = (rows: number) => {
    setCurrPage(1);
    setRowsPerPage(rows);
    handleGetData(
      1,
      rows,
      removeEmptyAndNullValues({
        ...validation.values,
        subGroupId:
          validation.values.subGroupId.length > 0
            ? validation.values.subGroupId
            : "",
        brandId:
          validation.values.brandId.length > 0
            ? validation.values.brandId?.map((dt: any) => dt.brandId)
            : "",
        campaignId:
          validation.values.campaignId.length > 0
            ? validation.values.campaignId?.map((dt: any) => dt.campaignId)
            : "",
        status:
          validation.values.status.length > 0 ? validation.values.status : "",
      })
    );
  };

  const customSort = (rows: any, selector: any, direction: string) => {
    return rows.sort((rowA: any, rowB: any) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === "desc" ? comparison * -1 : comparison;
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-1 me-3">
              <div className="d-flex align-items-center mb-1">
                <h4 className="mb-0 me-3">Messaging Logs</h4>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Analytics filters"
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message type"
                  >
                    {selectedTypeArr
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        <Button
                          key={i}
                          className={`btn me-1 ${
                            selectedType === dt || selectedType === "All"
                              ? "btn-secondary"
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            if (selectedType === dt || selectedType === "All") {
                              const otherType = selectedTypeArr.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType(otherType || "All");
                            } else {
                              setSelectedType(
                                selectedType === "All" ? dt : "All"
                              );
                            }
                          }}
                        >
                          {dt === "SMS" ? (
                            <i className="bi bi-chat-left-text" />
                          ) : (
                            <i className="bi bi-file-image" />
                          )}
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                      ))}
                  </div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Message direction"
                  >
                    {selectedTypeArr2
                      ?.filter((dt) => dt !== "All")
                      ?.map((dt, i) => (
                        <Button
                          key={i}
                          className={`btn me-1 ${
                            selectedType2 === dt || selectedType2 === "All"
                              ? "btn-secondary"
                              : "btn-outline-secondary hover-button"
                          }`}
                          onClick={() => {
                            if (
                              selectedType2 === dt ||
                              selectedType2 === "All"
                            ) {
                              const otherType = selectedTypeArr2.find(
                                (t) => t !== "All" && t !== dt
                              );
                              setSelectedType2(otherType || "All");
                            } else {
                              setSelectedType2(
                                selectedType2 === "All" ? dt : "All"
                              );
                            }
                          }}
                        >
                          <i
                            className={`bi bi-send${
                              dt === "Inbound" ? "-fill" : ""
                            }`}
                          />
                          <span className="ms-1 d-none d-sm-inline">{dt}</span>
                        </Button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center justify-content-end"
              style={{ flex: "1 1 0%" }}
            >
              <div className="d-flex align-items-center flex-wrap">
                {validation.values.from && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    From: {validation.values.from}
                  </span>
                )}
                {validation.values.to && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    To: {validation.values.to}
                  </span>
                )}
                {validation.values.subGroupId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Subgroup Id: {validation.values.subGroupId?.join(", ")}
                  </span>
                )}
                {validation.values.brandId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Brand Id:{" "}
                    {validation.values.brandId
                      ?.map((dt: any) => dt.brandId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.campaignId?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Campaign Id:{" "}
                    {validation.values.campaignId
                      ?.map((dt: any) => dt.campaignId)
                      ?.join(", ")}
                  </span>
                )}
                {validation.values.status?.length > 0 && (
                  <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                    Status: {validation.values.status?.join(", ")}
                  </span>
                )}
                {dateRangeTemp?.value ? (
                  dateRangeTemp?.value === "Custom" ? (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {validation.values.startDate}
                      {validation.values.endDate
                        ? ` to ${validation.values.endDate}`
                        : ""}
                    </span>
                  ) : (
                    <span className="details-tag mt-1 mr-2 d-flex align-items-center">
                      Date Range: {dateRangeTemp?.value}
                    </span>
                  )
                ) : null}
              </div>
              <Dropdown
                align="end"
                className="ms-2 filterDropDown2"
                show={open}
                onToggle={() => {
                  if (!datePickerModal) {
                    setOpen(!open);
                  }
                }}
              >
                <Dropdown.Toggle variant="secondary">
                  <i className="bx bx-filter-alt me-1" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-md p-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                  >
                    <Row>
                      <div className="mb-3">
                        <Form.Label htmlFor="signalHouseSID">Search</Form.Label>
                        <div className="d-flex">
                          <input
                            type="text"
                            placeholder="Search by Message SID"
                            className="form-control"
                            {...validation.getFieldProps("signalHouseSID")}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <Button className="btn btn-primary mb-3" type="submit">
                          Search
                        </Button>
                      </div>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="from">From</Form.Label>
                        <input
                          type="number"
                          placeholder="From"
                          className="form-control"
                          {...validation.getFieldProps("from")}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="to">To</Form.Label>
                        <input
                          type="number"
                          placeholder="To"
                          className="form-control"
                          {...validation.getFieldProps("to")}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="subGroupId">Sub Group</Form.Label>
                        <GroupSelect
                          isDisabled={!groupDetails?.records?.[0]?.group_id}
                          key={groupDetails?.records?.[0]?.group_id}
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.subGroupId &&
                              validation?.errors?.subGroupId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.subGroupId?.length > 0
                              ? validation.values.subGroupId.map(
                                  (dt: string) => ({
                                    sub_group_id: dt,
                                  })
                                )
                              : null
                          }
                          name="subGroupId"
                          loadOptions={asyncSubgroupList(
                            removeEmptyAndNullValues({
                              groupId:
                                groupDetails?.records?.[0]?.group_id || "",
                            }),
                            "subGroupId"
                          )}
                          getOptionValue={(option: any) => option?.sub_group_id}
                          getOptionLabel={(option: any) =>
                            option?.sub_group_name +
                            " - " +
                            option?.sub_group_id
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.sub_group_id !==
                                validation.values.subGroupId
                            ) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.map((dt: any) => dt.sub_group_id)
                              );
                              validation.setFieldValue("brandId", []);
                              validation.setFieldValue("campaignId", []);
                            } else if (!option) {
                              validation.setFieldValue("subGroupId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="brandId">Brand</Form.Label>
                        <BrandSelect
                          key={
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "brandId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.brandId &&
                              validation?.errors?.brandId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.brandId?.length > 0
                              ? validation.values.brandId
                              : null
                          }
                          loadOptions={asyncBrandList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                            }),
                            "brandId"
                          )}
                          getOptionValue={(option: any) => option?.brandId}
                          getOptionLabel={(option: any) =>
                            option?.displayName + " - " + option?.brandId
                          }
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.brandId === validation.values.brandId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                }))
                              );
                              validation.setFieldValue("campaignId", []);
                            } else {
                              validation.setFieldValue("brandId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="campaignId">Campaign</Form.Label>
                        <AsyncPaginate
                          key={
                            validation.values.brandId
                              ?.map((dt: any) => dt.brandId)
                              .toString() ||
                            validation.values.subGroupId.toString() ||
                            groupDetails?.records?.[0]?.group_id ||
                            "campaignId"
                          }
                          isMulti={true}
                          isClearable={true}
                          isSearchable={true}
                          styles={styleObj(
                            validation?.touched?.campaignId &&
                              validation?.errors?.campaignId
                          )}
                          theme={customSelectTheme}
                          value={
                            validation.values.campaignId?.length > 0
                              ? validation.values.campaignId
                              : null
                          }
                          loadOptions={asyncCampaignList(
                            removeEmptyAndNullValues({
                              subGroupId:
                                validation.values.subGroupId.length > 0
                                  ? validation.values.subGroupId
                                  : "",
                              brandId:
                                validation.values.brandId.length > 0
                                  ? validation.values.brandId?.map(
                                      (dt: any) => dt.brandId
                                    )
                                  : "",
                            }),
                            "campaignId"
                          )}
                          getOptionValue={(option: any) => option?.campaignId}
                          getOptionLabel={(option: any) => option?.campaignId}
                          onChange={(option: any) => {
                            if (
                              option &&
                              option.campaignId === validation.values.campaignId
                            ) {
                              return;
                            }
                            if (option) {
                              validation.setFieldValue(
                                "subGroupId",
                                option.reduce(
                                  (acc: string[], dt: any) => {
                                    if (
                                      dt?.subGroupId &&
                                      Array.isArray(dt.subGroupId)
                                    ) {
                                      dt.subGroupId.forEach(
                                        (subGroup: string) => {
                                          if (
                                            !acc.includes(subGroup) &&
                                            !validation.values.subGroupId.includes(
                                              subGroup
                                            )
                                          ) {
                                            acc.push(subGroup);
                                          }
                                        }
                                      );
                                    }
                                    return acc;
                                  },
                                  [...validation.values.subGroupId]
                                )
                              );
                              validation.setFieldValue(
                                "brandId",
                                option.reduce(
                                  (acc: any[], dt: any) => {
                                    const existingItem =
                                      validation.values.brandId.find(
                                        (item: any) =>
                                          item.brandId === dt.brandId &&
                                          JSON.stringify(item.subGroupId) ===
                                            JSON.stringify(dt.subGroupId)
                                      );
                                    if (!existingItem) {
                                      acc.push({
                                        subGroupId: dt?.subGroupId || [],
                                        brandId: dt?.brandId || "",
                                      });
                                    }
                                    return acc;
                                  },
                                  [...validation.values.brandId]
                                )
                              );
                              validation.setFieldValue(
                                "campaignId",
                                option.map((dt: any) => ({
                                  subGroupId: dt?.subGroupId || [],
                                  brandId: dt?.brandId || "",
                                  campaignId: dt?.campaignId || "",
                                }))
                              );
                            } else {
                              validation.setFieldValue("campaignId", []);
                            }
                          }}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="status">Status</Form.Label>
                        <Select
                          name="status"
                          isMulti={true}
                          isClearable={true}
                          styles={styleObj(
                            validation?.touched?.status &&
                              validation?.errors?.status
                          )}
                          theme={customSelectTheme}
                          options={[
                            { label: "Sent", value: "sent" },
                            { label: "Created", value: "created" },
                            { label: "Failed", value: "failed" },
                            { label: "Delivered", value: "delivered" },
                            { label: "Received", value: "received" },
                            { label: "Enqueued", value: "enqueued" },
                            { label: "Dequeued", value: "dequeued" },
                          ]}
                          onChange={(selectedOptions: any) => {
                            if (selectedOptions && selectedOptions.length > 0) {
                              validation.setFieldValue(
                                "status",
                                selectedOptions.map(
                                  (option: any) => option.value
                                )
                              );
                            } else {
                              validation.setFieldValue("status", []);
                            }
                          }}
                          value={
                            validation.values.status?.length > 0
                              ? validation.values.status.map(
                                  (status: string) => ({
                                    label: capitalizeString(status),
                                    value: status,
                                  })
                                )
                              : null
                          }
                        />
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Label htmlFor="dateRange">Date Range</Form.Label>
                        <Select
                          isClearable={true}
                          styles={styleObj(false)}
                          theme={customSelectTheme}
                          options={dateRangeArr}
                          onChange={(e: any) => {
                            setDateRangeTemp(e);
                            let startDate = "";
                            let endDate = moment()
                              .endOf("day")
                              .format("MM-DD-YYYY");

                            if (e?.value === "24 hours") {
                              startDate = moment()
                                .subtract(1, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "7 days") {
                              startDate = moment()
                                .subtract(7, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "30 days") {
                              startDate = moment()
                                .subtract(30, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else if (e?.value === "120 days") {
                              startDate = moment()
                                .subtract(120, "days")
                                .startOf("day")
                                .format("MM-DD-YYYY");
                            } else {
                              endDate = "";
                            }

                            validation.setFieldValue("createdAt", "");
                            validation.setFieldValue("startDate", startDate);
                            validation.setFieldValue("endDate", endDate);
                          }}
                          value={dateRangeTemp}
                        />
                      </Col>
                      {dateRangeTemp?.value === "Custom" ? (
                        <Col sm={6} className="mb-3">
                          <Form.Label htmlFor="createdAt">
                            Select Range
                          </Form.Label>
                          <Flatpickr
                            className="form-control"
                            name="createdAt"
                            placeholder="Select Range"
                            options={{
                              enableTime: false,
                              onOpen: () => {
                                setDatePickerModal(true);
                              },
                              onClose: () => {
                                setDatePickerModal(false);
                              },
                              altFormat: "F j, Y",
                              dateFormat: "m-d-Y",
                              mode: "range",
                              onChange: (
                                value: any,
                                dateStr: string,
                                instance: any
                              ) => {
                                validation.setFieldValue(
                                  "createdAt",
                                  dateStr || ""
                                );
                                value?.[0] &&
                                  validation.setFieldValue(
                                    "startDate",
                                    moment(value?.[0]).format("MM-DD-YYYY")
                                  );
                                value?.[1] &&
                                  validation.setFieldValue(
                                    "endDate",
                                    moment(value?.[1]).format("MM-DD-YYYY")
                                  );
                              },
                            }}
                            value={validation.values.createdAt || ""}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        className="btn btn-secondary me-2"
                        type="button"
                        disabled={loading2}
                        onClick={() => {
                          handleGetData(
                            currPage,
                            rowsPerPage,
                            removeEmptyAndNullValues({
                              ...validation.initialValues,
                              subGroupId: "",
                              brandId: "",
                              campaignId: "",
                              status: "",
                            })
                          );
                          validation.resetForm();
                          setDatePickerModal(false);
                          setDateRangeTemp(null);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading2}
                      >
                        {loading2 && <Spinner size="sm" animation="border" />}{" "}
                        Apply
                      </Button>
                    </div>
                  </Form>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body className="listing-table">
                {loading2 ? (
                  <div>
                    <img
                      src={Loader}
                      className={`position-absolute top-50 start-50 translate-middle`}
                    />
                  </div>
                ) : (
                  <Datatable
                    data={
                      Array.isArray(allMessageLogs.records)
                        ? allMessageLogs.records
                        : []
                    }
                    columns={columns}
                    handleRowClick={handleRowClick}
                    handlePageChange={handlePageChange}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    currPage={currPage}
                    rowsPerPage={rowsPerPage}
                    totalRecords={allMessageLogs?.totalRecords}
                    customSort={customSort}
                  />
                )}
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(MessageLogsList);
