import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import {
  asyncActivePhoneNumberList,
  calculateMessageSegments,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import {
  sendMMSThunk,
  uploadMmsFileThunk,
  updateUserStepsThunk,
  openModal,
} from "slices/thunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { createSelector } from "reselect";
import { AsyncPaginate } from "react-select-async-paginate";
import Lightbox from "react-image-lightbox";
import Tooltip from "rc-tooltip";

interface FormData {
  apiKey: string;
  publicKey: string;
}

const SendMMS = (props: any) => {
  document.title = "Signal House Portal Send an MMS";
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.AuthUser,
    (state: any) => state.Login,
    (authUser, login) => ({
      stepsData: authUser.stepsData,
      user: authUser.authUser,
      loginData: login.loginData,
    })
  );

  const { stepsData, user, loginData } = useSelector(selectAccount);
  const messagingData: any = useSelector((state: any) => state.Messages);
  const phoneRegExp = /^\d{1,3}\d{4,14}$/;
  const urlRegExp =
    /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/g;

  const [fromTemp, setFromTemp] = useState<any>({});
  const [imgLoading, setImgLoading] = useState(false);
  const [prevSrc, setPrevSrc] = useState("");
  const [isOpenImgLightBox, setIsOpenImgLightBox] = useState(false);
  const [isGroupMessaging, setIsGroupMessaging] = useState(false);
  const [isShowShortLink, setIsShowShortLink] = useState(false);
  const [isShortLink, setIsShortLink] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    apiKey: "",
    publicKey: "",
  });

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: "",
      to: [],
      statusCallBackUrl: "",
      body: "",
      mediaUrls: [],
      attachments: [],
      groupPhoneNumbers: [],
    },
    validationSchema: Yup.object().shape({
      from: Yup.string().required("Please Enter Phone Number"),
      to: Yup.array()
        .of(
          Yup.string()
            .matches(
              phoneRegExp,
              "Phone number must include 1 to 3 digits for the country code followed by 4 to 14 digits for the number"
            )
            .required("Please Enter To Phone Number")
        )
        .test(
          "attachments-or-mediaUrls",
          "Please Enter to phone number",
          function (value) {
            if (value && value.length > 0) {
              return true;
            }
            return this.createError({
              message: "Please Enter To Phone Number",
            });
          }
        ),
      statusCallBackUrl: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .notRequired(),
      body: Yup.string().min(5).max(1600).required("Please Enter Message"),
      mediaUrls: Yup.array()
        .of(Yup.string().url("Must be a valid URL"))
        .test(
          "mediaUrls-or-attachments",
          "Either mediaUrls or attachments must be provided with at least one item",
          function (value) {
            const { attachments } = this.parent;
            if (
              (value && value.length > 0) ||
              (attachments && attachments.length > 0)
            ) {
              return true;
            }
            return this.createError({
              message:
                "At least one media URL is required if no attachments are provided",
            });
          }
        ),
      attachments: Yup.array().test(
        "attachments-or-mediaUrls",
        "Either attachments or mediaUrls must be provided with at least one item",
        function (value) {
          const { mediaUrls } = this.parent;
          if (
            (value && value.length > 0) ||
            (mediaUrls && mediaUrls.length > 0)
          ) {
            return true;
          }
          return this.createError({
            message:
              "At least one attachment is required if no media URLs are provided",
          });
        }
      ),
    }),
    onSubmit: (values) => {
      const cb2 = () => {
        validation.resetForm();
        validation.setFieldValue("from", fromTemp?.phoneNumber || "");
        !stepsData?.sendFirstSms &&
          dispatch(
            updateUserStepsThunk({ sendFirstSms: true }, user?.completedSteps)
          );
      };

      const cb = (results: any) => {
        const obj = !isGroupMessaging
          ? {
              from: values.from,
              to: values.to?.map((dt: any) => dt.toString()),
              statusCallBackUrl: values.statusCallBackUrl,
              body: values.body,
              mediaUrls: values.mediaUrls,
              attachments: results || [],
              shortLink: isShortLink,
            }
          : {
              from: values.from,
              groupPhoneNumbers: values.to?.map((dt: any) => dt.toString()),
              statusCallBackUrl: values.statusCallBackUrl,
              body: values.body,
              mediaUrls: values.mediaUrls,
              attachments: results || [],
              shortLink: isShortLink,
            };

        dispatch(sendMMSThunk(removeEmptyAndNullValues(obj), () => cb2()));
      };

      values.attachments.length > 0
        ? dispatch(uploadMmsFileThunk(values.attachments, cb))
        : cb(values.attachments);
    },
  });

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    obj?.apiKey && setFormData((prev) => ({ ...prev, apiKey: obj?.apiKey }));
    obj?.authToken &&
      setFormData((prev) => ({ ...prev, publicKey: obj?.authToken }));
  }, [loginData]);

  useEffect(() => {
    if (validation.values.body) {
      const temp = urlRegExp.test(validation.values.body);
      setIsShowShortLink(temp);
      !temp && setIsShortLink(false);
    }
  }, [validation.values.body]);

  const handleDownloadImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...validation.values.attachments];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      let link = document.createElement("a");
      link.href = temp2;
      link.download = temp?.[ind]?.name || temp?.[ind]?.fileName || "img.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setImgLoading(false);
    };
    cb(temp?.[ind]);
  };

  const handleViewImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...validation.values.attachments];
    const cb = (img: any) => {
      const temp2 = URL.createObjectURL(img);
      setPrevSrc(temp2);
      setIsOpenImgLightBox(true);
      setImgLoading(false);
    };
    cb(temp?.[ind]);
  };

  const handleDeleteImg = (ind: number) => {
    setImgLoading(true);
    const temp = [...validation.values.attachments];
    temp.splice(ind, 1);
    validation.setFieldValue("attachments", temp);
    setImgLoading(false);
  };

  const handleAcceptedFiles = (files: any, rejFiles: any) => {
    if (validation.values.attachments?.length > 4) {
      toast.error("Max 5 uploads is allowed");
      return;
    }

    const totalSize = [...validation.values.attachments, ...files].reduce(
      (acc: number, file: { size: number }) => acc + file.size,
      0
    );
    if (totalSize > 1 * 1024 * 1024) {
      toast.error("Exceeded maximum total size (max: 1MB)");
      return;
    }

    rejFiles?.length > 0 && console.log("rejFiles", rejFiles);
    rejFiles?.forEach((file: any) => {
      file.errors.forEach((err: { code: string; message: string }) => {
        if (err.code === "file-too-large") {
          toast.error("File is larger than 5 MB");
        }

        if (err.code === "file-invalid-type") {
          toast.error(err.message || "Something went wrong");
        }
      });
    });

    if (files?.length > 0) {
      const cb = (files: any) => {
        if (typeof files[0] === "object" && files[0] !== null) {
          validation.setFieldValue("attachments", [
            ...validation.values.attachments,
            files[0],
          ]);
        }
      };
      cb(files);
    }
  };

  const copyText = (e: any, type: string) => {
    e && e.preventDefault();
    const textToCopy = document.getElementById(type) as HTMLInputElement;
    if (textToCopy) {
      const textArea = document.createElement("textarea");
      textArea.id = "copyDiv";
      textArea.style.position = "absolute";
      textArea.style.bottom = "0px";
      textArea.textContent = textToCopy.textContent;
      document.body.append(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textArea?.value);
      toast.success("Text copied");
      const myDiv = document.getElementById("copyDiv") as HTMLInputElement;
      myDiv.remove();
    }
  };

  const createCurl = () => {
    let string = "";
    if (validation.values.from)
      string += `\n"from": "${validation.values.from}",`;
    if (validation.values.body)
      string += `\n"body": "${validation.values.body}",`;
    if (validation.values.statusCallBackUrl)
      string += `\n"statusCallBackUrl": "${validation.values.statusCallBackUrl}",`;
    if (validation.values.mediaUrls?.length > 0)
      string += `\n"mediaUrls": [${validation.values.mediaUrls?.map(
        (dt: string, i: number) =>
          `\n"${dt}"${i === validation.values.mediaUrls.length - 1 ? "\n" : ""}`
      )}],`;
    if (validation.values.attachments?.length > 0)
      string += `\n"attachments": [${validation.values.attachments?.map(
        (dt: string, i: number) =>
          `\n"${dt}"${
            i === validation.values.attachments.length - 1 ? "\n" : ""
          }`
      )}],`;
    if (isGroupMessaging) {
      if (validation.values?.to?.length > 0)
        string += `\n"groupPhoneNumbers": [${validation.values.to?.map(
          (dt: string, i: number) =>
            `\n"${dt}"${i === validation.values.to.length - 1 ? "\n" : ""}`
        )}],`;
    } else {
      if (validation.values?.to?.length > 0)
        string += `\n"to": [${validation.values.to?.map(
          (dt: string, i: number) =>
            `\n"${dt}"${i === validation.values.to.length - 1 ? "\n" : ""}`
        )}],`;
    }
    string += `\n"shortLink": ${isShortLink},`;

    string = string.substring(0, string.length - 1);
    return string;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {isOpenImgLightBox && (
          <Lightbox
            enableZoom={false}
            mainSrc={prevSrc}
            onCloseRequest={() => {
              setIsOpenImgLightBox(false);
              setPrevSrc("");
            }}
          />
        )}
        <Container fluid>
          <BreadCrumb title="Send an MMS" />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row>
                    <Col xl={6}>
                      <h6 className="card-title mb-3">
                        Step 1: Recipients and Senders
                      </h6>
                      <div className="mb-3">
                        <Form.Label htmlFor="from">
                          From phone number{" "}
                          <span className="text-primary">*</span>
                        </Form.Label>
                        <AsyncPaginate
                          key="from"
                          styles={styleObj(
                            validation?.touched?.from &&
                              validation?.errors?.from
                          )}
                          theme={customSelectTheme}
                          value={fromTemp || {}}
                          loadOptions={asyncActivePhoneNumberList(
                            removeEmptyAndNullValues({
                              tenDLCStatus: "Ready",
                              numberStatus: "Active",
                            }),
                            "phoneNumber"
                          )}
                          getOptionValue={(option: any) => option?.phoneNumber}
                          getOptionLabel={(option: any) => option?.phoneNumber}
                          onChange={(option: any) => {
                            setFromTemp(option);
                            validation.setFieldValue(
                              "from",
                              option.phoneNumber
                            );
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                        {validation.touched.from && validation.errors.from ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation?.errors?.from}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>
                      <div
                        className="form-check form-switch form-switch-md"
                        dir="ltr"
                      >
                        <Form.Check
                          type="checkbox"
                          id="group-messaging-switch"
                          role="switch"
                          label="Group Messaging"
                          checked={isGroupMessaging}
                          onChange={() =>
                            setIsGroupMessaging(!isGroupMessaging)
                          }
                          style={{
                            marginBottom: "20px",
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label htmlFor="to">
                          {isGroupMessaging
                            ? "Group phone numbers"
                            : "To phone number"}{" "}
                          <span className="text-primary">*</span>
                        </Form.Label>
                        {validation.touched.to && validation.errors.to ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mb-3"
                          >
                            {validation?.errors?.to}
                          </Form.Control.Feedback>
                        ) : null}
                        <Button
                          variant="secondary"
                          className="d-flex align-items-center"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "AddPhoneNumber",
                                data: {
                                  title: "Add Phone Number",
                                  phoneArr: validation.values.to,
                                  setPhone: (data: any) =>
                                    validation.setFieldValue("to", data),
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          <i className="bx bx-plus me-1"></i> Add Phone Number
                        </Button>
                        <ul className="hstack gap-2 list-unstyled my-3 flex-wrap">
                          {validation.values.to?.map(
                            (dt: string, i: number) => (
                              <li key={i}>
                                <div
                                  className="d-block p-2 bg-light bg-opacity-50 rounded"
                                  style={{ width: "max-content" }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        <i className="ri ri-phone-line fs-lg"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-0 text-break">
                                        {dt || ""}
                                      </h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          const temp = [
                                            ...validation.values.to,
                                          ];
                                          temp.splice(i, 1);
                                          validation.setFieldValue("to", temp);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="mb-3">
                        <Form.Label
                          htmlFor="statusCallBackUrl"
                          className="position-relative"
                        >
                          Status Call-Back URL{" "}
                          <Tooltip
                            placement="bottom"
                            overlay={
                              "A webhook URL for a success or failure notification concerning this message."
                            }
                          >
                            <i
                              className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                              style={{ bottom: -2 }}
                            />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          type="url"
                          name="statusCallBackUrl"
                          onChange={validation?.handleChange}
                          value={validation.values.statusCallBackUrl}
                          isInvalid={
                            validation?.touched?.statusCallBackUrl &&
                            validation?.errors?.statusCallBackUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.statusCallBackUrl &&
                        validation.errors.statusCallBackUrl ? (
                          <Form.Control.Feedback type="invalid">
                            {validation?.errors?.statusCallBackUrl}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      <h6 className="card-title mb-3 mt-4">
                        Step 2: Sending messages
                      </h6>

                      <div className="mb-3">
                        <Form.Label htmlFor="body">
                          Message <span className="text-primary">*</span>
                        </Form.Label>
                        <textarea
                          id="body"
                          name="body"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.body || ""}
                          className={`form-control ${
                            validation.touched?.body && validation.errors?.body
                              ? "is-invalid"
                              : ""
                          }`}
                          rows={3}
                          minLength={5}
                          style={{ resize: "none" }}
                        />
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {validation.touched?.body &&
                            validation.errors?.body ? (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {validation.errors?.body}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                          <div className="">
                            Segments:{" "}
                            {
                              calculateMessageSegments(validation.values.body)
                                ?.segments
                            }{" "}
                            Length: {validation.values.body?.length}
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Form.Label
                          htmlFor="mediaUrls"
                          className="position-relative"
                        >
                          Media URLs{" "}
                          {validation.values.attachments?.length === 0 && (
                            <span className="text-primary">*</span>
                          )}
                          <Tooltip
                            placement="bottom"
                            overlay={
                              <p className="mb-0">
                                Media URLs support any link which returns the
                                following files: <b>Images</b> (JPEG, JPG, PNG,
                                GIF), <b>Audio</b> files, <b>Video</b> files,
                                and <b>Text documents</b> (PDF, DOCX, etc.)
                              </p>
                            }
                          >
                            <i
                              className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                              style={{ bottom: 17 }}
                            />
                          </Tooltip>
                          <p className="text-muted mb-0">
                            You can have up to 5 media URL
                          </p>
                        </Form.Label>
                        {validation.touched?.mediaUrls &&
                        validation.errors?.mediaUrls ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mb-3"
                          >
                            {validation.errors?.mediaUrls}
                          </Form.Control.Feedback>
                        ) : null}
                        <Button
                          variant="secondary"
                          className="d-flex align-items-center"
                          disabled={
                            validation.values.mediaUrls?.length > 4 ||
                            validation.values.attachments?.length > 0
                          }
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "AddUrl",
                                data: {
                                  title: "Add Media URL",
                                  mediaUrlsArr: validation.values.mediaUrls,
                                  setUrls: (data: any) =>
                                    validation.setFieldValue("mediaUrls", data),
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          <i className="bx bx-plus me-1"></i> Add URL
                        </Button>
                        <ul className="vstack gap-2 list-unstyled my-3">
                          {validation.values.mediaUrls?.map(
                            (dt: string, i: number) => (
                              <li key={i}>
                                <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        <i className="ri ri-link-m fs-lg"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-0 text-break">
                                        {dt || ""}
                                      </h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          window.open(dt, "_blank");
                                        }}
                                      />
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          const temp = [
                                            ...validation.values.mediaUrls,
                                          ];
                                          temp.splice(i, 1);
                                          validation.setFieldValue(
                                            "mediaUrls",
                                            temp
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                      <div className="mb-3">
                        <Form.Label htmlFor="attachments">
                          Attachments{" "}
                          {validation.values.mediaUrls?.length === 0 && (
                            <span className="text-primary">*</span>
                          )}
                          <p className="text-muted mb-0">
                            You can have up to 5 attachments, and should be less
                            than 1MB to ensure delivery
                          </p>
                        </Form.Label>
                        {validation.touched.attachments &&
                        validation.errors.attachments ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block mb-3"
                          >
                            {validation?.errors?.attachments}
                          </Form.Control.Feedback>
                        ) : null}
                        <div
                          style={{
                            pointerEvents:
                              validation.values.mediaUrls?.length > 0
                                ? "none"
                                : "auto",
                            opacity:
                              validation.values.mediaUrls?.length > 0
                                ? "0.5"
                                : "1",
                          }}
                        >
                          <Dropzone
                            onDrop={(
                              acceptedFiles: any,
                              fileRejections: any
                            ) => {
                              handleAcceptedFiles(
                                acceptedFiles,
                                fileRejections
                              );
                            }}
                            maxSize={5242880} // 5MB
                            multiple={false}
                            accept={{
                              "image/*": [],
                            }}
                          >
                            {({ getRootProps }: any) => (
                              <div className="dropzone dz-clickable text-center border rounded">
                                <div
                                  className="dz-message needsclick cursor-pointer"
                                  {...getRootProps()}
                                >
                                  <div className="mb-3">
                                    <i className="ri-camera-fill text-muted fs-8xl" />
                                  </div>
                                  <p className="fs-lg">
                                    Drop a image file to upload or click to
                                    upload
                                  </p>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <ul className="vstack gap-2 list-unstyled my-3">
                          {validation.values.attachments?.map(
                            (
                              dt: { type: string; name?: string },
                              i: number
                            ) => (
                              <li key={i}>
                                <div className="d-block p-2 bg-light bg-opacity-50 rounded">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        {dt?.type.startsWith("image/") ? (
                                          <i className="ri ri-image-line fs-lg"></i>
                                        ) : dt?.type.startsWith("video/") ? (
                                          <i className="ri ri-video-line fs-lg"></i>
                                        ) : (
                                          <i className="ri ri-file-line fs-lg"></i>
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-0">{dt?.name || ""}</h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-download-2-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !imgLoading && handleDownloadImg(i);
                                        }}
                                      />
                                      <i
                                        className="ri-eye-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !imgLoading && handleViewImg(i);
                                        }}
                                      />
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          !imgLoading && handleDeleteImg(i);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>

                      {isShowShortLink && (
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            label="Short Link"
                            checked={isShortLink}
                            onChange={() => setIsShortLink(!isShortLink)}
                            style={{
                              marginBottom: "20px",
                            }}
                          />
                        </div>
                      )}
                    </Col>
                    <Col xl={6}>
                      <Card className="mb-4">
                        <Card.Header
                          style={{
                            background: "rgb(31, 48, 76)",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                          }}
                        >
                          <h6 className="fs-md text-white">Request</h6>
                        </Card.Header>
                        <Card.Body
                          className="text-white position-relative"
                          style={{
                            background: "rgb(18, 28, 45)",
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                          }}
                        >
                          <div
                            className="position-absolute end-0 me-3 cursor-pointer"
                            onClick={(e) => copyText(e, "request")}
                          >
                            <i className="bx bx-copy align-middle me-2 fs-3xl" />
                          </div>
                          <div id="request">
                            <pre className="fs-sm">
                              {`curl -X 'POST' \\
 ${`${
   process.env.REACT_APP_BACKEND_URL || "https://devapi.signalhouse.io"
 }/message/sendMMS`} \\
  -H 'accept: application/json' \\
  -H 'apiKey: ${formData.apiKey}' \\
  -H 'authToken: ${formData.publicKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{${createCurl()}
}'`}
                            </pre>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <div className="hstack gap-2">
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        validation.resetForm();
                        validation.setFieldValue(
                          "from",
                          fromTemp?.phoneNumber || ""
                        );
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={messagingData?.loading}
                    >
                      {messagingData?.loading && (
                        <Spinner size="sm" animation="border" />
                      )}{" "}
                      Send Test Message
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SendMMS);
