import { getInitials } from "helpers/common";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getFileThunk } from "slices/thunk";

const ProfileDropdown = () => {
  const dispatch = useDispatch<any>();
  const selectUser = createSelector(
    (state: any) => state.AuthUser,
    (user) => user?.authUser
  );
  const user = useSelector(selectUser);
  const init = getInitials(`${user?.firstName} ${user?.lastName}`);
  const [profileUrl, setProfileUrl] = useState(user?.profilePictureLink || "");

  const handleNavigation = () => {
    window.open(
      `${
        process.env.REACT_APP_FRONTEND_URL || "https://devapi.signalhouse.io"
      }/apidoc`,
      "_blank"
    );
  };

  useEffect(() => {
    if (user?.profilePictureLink) {
      const cb = (img: Blob | MediaSource) => {
        const temp = URL.createObjectURL(img);
        setProfileUrl(temp);
      };
      dispatch(getFileThunk(user?.profilePictureLink, cb));
    }
  }, [user?.profilePictureLink]);

  return (
    <React.Fragment>
      <Dropdown className="ms-sm-3 header-item topbar-user">
        <Dropdown.Toggle
          type="button"
          className="btn bg-transparent border-0 arrow-none"
          id="page-header-user-dropdown"
        >
          <span className="d-flex align-items-center">
            {profileUrl ? (
              <img
                className="rounded-circle header-profile-user"
                src={profileUrl}
                onError={() => {
                  setProfileUrl("");
                }}
                alt="Header Avatar"
              />
            ) : (
              <div className="avatar-xs">
                <div className="avatar-title rounded-circle bg-secondary">
                  {init}
                </div>
              </div>
            )}
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user?.firstName} {user?.lastName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-sm text-muted user-name-sub-text">
                {user?.companyName}
              </span>
            </span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <h6 className="dropdown-header">
            Welcome {user?.firstName} {user?.lastName}!
          </h6>
          <Dropdown.Item href={process.env.PUBLIC_URL + "/settings/general"}>
            <i className="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Edit Profile</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleNavigation}>
            <i className=" ri-file-list-3-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Documentation</span>
          </Dropdown.Item>
          <Dropdown.Item href="/all-notifications">
            <i className="mdi mdi-bell-outline text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Notifications</span>
          </Dropdown.Item>
          <div className="dropdown-divider"></div>
          {user?.roles?.[0] !== "company_standard" && (
            <Dropdown.Item href={process.env.PUBLIC_URL + "/settings/billing"}>
              <i className="mdi mdi-wallet text-muted fs-lg align-middle me-1"></i>{" "}
              <span className="align-middle">
                Balance :{" "}
                <b>${user?.balance ? (+user?.balance)?.toFixed(2) : 0} USD</b>
              </span>
            </Dropdown.Item>
          )}
          <Dropdown.Item href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
